import {Tr} from "@/i18n/i18n";
import {Creature, CreatureEffectType, Skill, WarriorClass} from "@/store/level/types";
import {SkillType} from "@/store/place/types";
import {Player} from "@/store/player/types";
import PlayerStore from "@/store/player";
import {Keys} from "@/constants/key";

export class CreatureUtils {

    public static getUnitAttackValue(creature: Creature) {
        return creature.skills.find((s:Skill) => s.skillType == SkillType.ATTACK)!.valueCurrent;
    }

    public static getUnitDefenceValue(creature: Creature) {
        return creature.skills.find((s:Skill) => s.skillType == SkillType.DEFENCE)!.valueCurrent;
    }

    public static getUnitHealthCurrentValue(creature: Creature) {
        return creature.skills.find((s:Skill) => s.skillType == SkillType.HEALTH)!.valueCurrent;
    }

    public static isAliveAndNotFrozen(creature: Creature) {
        if (creature.skills.find((s:Skill) => s.skillType == SkillType.HEALTH)!.valueCurrent <= 0) {
            return false;
        }
        for (let eff of creature.effects) {
            if (eff.type === CreatureEffectType.FROZEN && eff.movesRemain > 0) {
                return false;
            }
        }
        return true;
    }

    public static isAlive(creature: Creature) {
        if (creature.skills.find((s:Skill) => s.skillType == SkillType.HEALTH)!.valueCurrent <= 0) {
            return false;
        }
        return true;
    }

    public static getUnitHealth(creature: Creature): Skill {
        return creature.skills.find((s:Skill) => s.skillType == SkillType.HEALTH)!;
    }

    public static getUnitHealthMax(creature: Creature) {
        return creature.skills.find((s:Skill) => s.skillType == SkillType.HEALTH)!.valueTotal;
    }

    public static getUnitImmortality(creature: Creature): Skill {
        return creature.skills.find((s:Skill) => s.skillType == SkillType.IMMORTALITY)!;
    }

    public static getUnitPoison(creature: Creature): Skill {
        return creature.skills.find((s:Skill) => s.skillType == SkillType.POISON)!;
    }

    public static findFirstHavingArtifact(player: Player) {
        for (let c of player.creatures) {
            if (c.artifacts.length > 0) {
                return c;
            }
        }
        return undefined;
    }

    public static findFirstCreatureAbleToGetArtifact(player: Player, maxSlots: number, currentCreature?: Creature) {
        if (currentCreature) {
            return player.creatures.filter(c => c.imageId !== currentCreature.imageId && !c.temporal).find(cr => cr.slotsCount < maxSlots);
        }
        return player.creatures.filter(c => !c.temporal).find(cr => cr.slotsCount < maxSlots);

    }

    public static findNonTemporalCreatures(creatures: Creature[]) {
        return creatures.filter(c => !c.temporal);
    }

    public static findCat(): Creature {
        return PlayerStore.getPlayer().creatures.filter(c => c.imageId.includes('cat'))[0];
    }

    public static findCatOrRandom(): Creature {
        let list = PlayerStore.getPlayer().creatures.filter(c => c.imageId.includes('cat'));
        if (list.length > 0) {
            return list[0];
        } else {
            return PlayerStore.getPlayer().creatures.filter(c => c.combative)[0];
        }
    }
    public static findHumanOrRandom(excludingId: number): Creature {
        let list = PlayerStore.getPlayer().creatures.filter(c => c.imageId.includes('man'));
        if (list.length > 0) {
            return list[0];
        } else {
            return PlayerStore.getPlayer().creatures.filter(c => c.combative && c.id !== excludingId)[0];
        }
    }

    public static getOrderedCreaturesList(creatures: Creature[]): Creature[] {
        const orderedCreatures: Creature[] = new Array(creatures.length);

        let combativeCreatureIndex = 5;
        let nonCombativeCreatureIndex = 6;

        for (let i = 0; i < creatures.length; i++) {
            if (creatures[i].combative) {
                orderedCreatures[combativeCreatureIndex] = creatures[i];
                combativeCreatureIndex--;
            }
            else {
                orderedCreatures[nonCombativeCreatureIndex] = creatures[i];
                nonCombativeCreatureIndex++;
            }
        }

        return orderedCreatures;
    }

    // Сортировка по возрастанию
    public static sortCreaturesAscending(creatures: Creature[]): Creature[] {
        return Object.assign([], creatures).sort((a: Creature, b: Creature) => {
            return a.id - b.id;
        });
    }
    // Сортировка по убыванию
    public static sortCreaturesDescending(creatures: Creature[]): Creature[] {
        return Object.assign([], creatures).sort((a: Creature, b: Creature) => {
            return b.id - a.id;
        });
    }

    public static getCreatureById(creatureId: number): Creature {
        return PlayerStore.getPlayer()!.creatures.find((c: Creature) => c.id == creatureId)!;
    }

    public static exists(creatureId: number): boolean {
        return this.getCreatureById(creatureId) !== undefined;
    }
    public static getForceIconForTenX(forceNum: number): number {
        switch (forceNum) {
            case 0:
                return 40;
            case 1:
                return 42;
            case 2:
                return 40;
            case 3:
                return 34;
            case 4:
                return 22;

            case 5:
                return -22;
            case 6:
                return -34;
            case 7:
                return -40;
            case 8:
                return -42;
            case 9:
                return -40;
        }
        return 0;
    }

    public static getForceIconForTenY(forceNum: number): number {
        switch (forceNum) {
            case 0:
                return 23;
            case 1:
                return 4;
            case 2:
                return -15;
            case 3:
                return -34;
            case 4:
                return -50;

            case 5:
                return -50;
            case 6:
                return -34;
            case 7:
                return -15;
            case 8:
                return 4;
            case 9:
                return 23;
        }
        return 0;
    }

    public static getForceIconX(forceNum: number): number {
        switch (forceNum) {
            case 0:
                return 37;
            case 1:
                return 42;
            case 2:
                return 41;
            case 3:
                return 36;
            case 4:
                return 26;
            case 5:
                return -26;
            case 6:
                return -36;
            case 7:
                return -41;
            case 8:
                return -42;
            case 9:
                return -37;
            case 10:
                return -16;
            case 11:
                return 16;
        }
        return 0;
    }

    public static getForceIconY(forceNum: number): number {
        switch (forceNum) {
            case 0:
                return 31;
            case 1:
                return 12;
            case 2:
                return -8;
            case 3:
                return -28;
            case 4:
                return -46;
            case 5:
                return -46;
            case 6:
                return -28;
            case 7:
                return -8;
            case 8:
                return 12;
            case 9:
                return 31;
            case 10:
                return -55;
            case 11:
                return -55;
        }
        return 0;
    }

    public static getForceIconBigX(forceNum: number): number {
        switch (forceNum) {
            case 0:
                return 38;
            case 1:
                return 41;
            case 2:
                return 35;
        }
        return 0;
    }

    public static getForceIconBigY(forceNum: number): number {
        switch (forceNum) {
            case 0:
                return 28;
            case 1:
                return 1;
            case 2:
                return -26;
        }
        return 0;
    }

    public static getForceAtlas(wc: WarriorClass) : string {
        switch (wc) {
            case WarriorClass.IFRIT:
            case WarriorClass.LIFE:
            case WarriorClass.DEATH:
            case WarriorClass.WIND:
            case WarriorClass.CHAOS:
            case WarriorClass.WALL:
            case WarriorClass.ELF:
            case WarriorClass.VAMPIRE:
            case WarriorClass.LORD:
            case WarriorClass.BERSERK:
            case WarriorClass.LUCK:
            case WarriorClass.SORCERER:
            case WarriorClass.PRIESTESS:
                return Keys.ATLAS_FORCES_2;
            case WarriorClass.AS:
            case WarriorClass.CYCLOP:
            case WarriorClass.KEEPER:
            case WarriorClass.KOSCHEY:
            case WarriorClass.LIZARD:
            case WarriorClass.POISON:
            case WarriorClass.SHADOW:
            case WarriorClass.SPHINX:
                return Keys.ATLAS_FORCES_6;
            case WarriorClass.DRYAD:
            case WarriorClass.FACELESS:
            case WarriorClass.GORGON:
            case WarriorClass.LIGHTSHADOW:
            case WarriorClass.INKUB:
            case WarriorClass.SUKKUB:
            case WarriorClass.MANTICORE:
            case WarriorClass.RIDER:
            case WarriorClass.NUN:
            case WarriorClass.FIRE:
            case WarriorClass.MARTYR:
            case WarriorClass.ABYSSSOUL:
            case WarriorClass.BABAYAGA:
            case WarriorClass.BALTAZAR:
            case WarriorClass.SIKJI:
                return Keys.ATLAS_FORCES_7;
        }
        return Keys.ATLAS_FORCES;
    }

    public static getForceAtlasBig(wc: WarriorClass) : string {
        switch (wc) {
            case WarriorClass.IFRIT:
            case WarriorClass.LIFE:
            case WarriorClass.DEATH:
            case WarriorClass.WIND:
            case WarriorClass.CHAOS:
            case WarriorClass.WALL:
            case WarriorClass.ELF:
            case WarriorClass.VAMPIRE:
            case WarriorClass.LORD:
            case WarriorClass.BERSERK:
            case WarriorClass.LUCK:
            case WarriorClass.SORCERER:
            case WarriorClass.PRIESTESS:
                return Keys.ATLAS_FORCES_BIG_2;
            case WarriorClass.AS:
            case WarriorClass.CYCLOP:
            case WarriorClass.KEEPER:
            case WarriorClass.KOSCHEY:
            case WarriorClass.LIZARD:
            case WarriorClass.POISON:
            case WarriorClass.SHADOW:
            case WarriorClass.SPHINX:
                return Keys.ATLAS_FORCES_BIG_6;
            case WarriorClass.DRYAD:
            case WarriorClass.FACELESS:
            case WarriorClass.GORGON:
            case WarriorClass.LIGHTSHADOW:
            case WarriorClass.INKUB:
            case WarriorClass.SUKKUB:
            case WarriorClass.MANTICORE:
            case WarriorClass.RIDER:
            case WarriorClass.NUN:
            case WarriorClass.FIRE:
            case WarriorClass.MARTYR:
            case WarriorClass.ABYSSSOUL:
            case WarriorClass.BABAYAGA:
            case WarriorClass.BALTAZAR:
            case WarriorClass.SIKJI:
                return Keys.ATLAS_FORCES_BIG_7;
        }
        return Keys.ATLAS_FORCES_BIG;
    }

    public static getForceAtlasLarge(wc: WarriorClass) : string {
        switch (wc) {
            case WarriorClass.IFRIT:
            case WarriorClass.LIFE:
            case WarriorClass.DEATH:
            case WarriorClass.WIND:
            case WarriorClass.CHAOS:
            case WarriorClass.WALL:
            case WarriorClass.ELF:
            case WarriorClass.VAMPIRE:
            case WarriorClass.LORD:
            case WarriorClass.BERSERK:
            case WarriorClass.LUCK:
            case WarriorClass.SORCERER:
            case WarriorClass.PRIESTESS:
            case WarriorClass.ANGEL:
            case WarriorClass.SHAMAN:
                return Keys.ATLAS_FORCES_LARGE_2;
            case WarriorClass.WARLOCK:
            case WarriorClass.DRUID:
            case WarriorClass.WITCH:
            case WarriorClass.DRAGONSLAYER:
            case WarriorClass.YOTUN:
            case WarriorClass.TITAN:
            case WarriorClass.ARCHITITAN:
            case WarriorClass.ARCHANGEL:
            case WarriorClass.ASG_PHOENIX:
                return Keys.ATLAS_FORCES_LARGE_3;
            case WarriorClass.AS:
            case WarriorClass.CYCLOP:
            case WarriorClass.KEEPER:
            case WarriorClass.KOSCHEY:
            case WarriorClass.LIZARD:
            case WarriorClass.POISON:
            case WarriorClass.SHADOW:
            case WarriorClass.SPHINX:
                return Keys.ATLAS_FORCES_LARGE_6;
            case WarriorClass.DRYAD:
            case WarriorClass.FACELESS:
            case WarriorClass.GORGON:
            case WarriorClass.LIGHTSHADOW:
            case WarriorClass.INKUB:
            case WarriorClass.SUKKUB:
            case WarriorClass.MANTICORE:
            case WarriorClass.RIDER:
            case WarriorClass.NUN:
            case WarriorClass.FIRE:
            case WarriorClass.MARTYR:
            case WarriorClass.ABYSSSOUL:
            case WarriorClass.BABAYAGA:
            case WarriorClass.BALTAZAR:
            case WarriorClass.SIKJI:
                return Keys.ATLAS_FORCES_LARGE_7;
        }

        return Keys.ATLAS_FORCES_LARGE;
    }

    public static getForceAtlasSemiLarge(wc: WarriorClass) : string {
        switch (wc) {
            case WarriorClass.AS:
            case WarriorClass.CYCLOP:
            case WarriorClass.KEEPER:
            case WarriorClass.KOSCHEY:
            case WarriorClass.LIZARD:
            case WarriorClass.POISON:
            case WarriorClass.SHADOW:
            case WarriorClass.SPHINX:
                return Keys.ATLAS_FORCES_SEMI_LARGE_6;
            case WarriorClass.DRYAD:
            case WarriorClass.FACELESS:
            case WarriorClass.GORGON:
            case WarriorClass.LIGHTSHADOW:
            case WarriorClass.INKUB:
            case WarriorClass.SUKKUB:
            case WarriorClass.MANTICORE:
            case WarriorClass.RIDER:
            case WarriorClass.NUN:
            case WarriorClass.FIRE:
            case WarriorClass.MARTYR:
            case WarriorClass.ABYSSSOUL:
            case WarriorClass.BABAYAGA:
            case WarriorClass.BALTAZAR:
            case WarriorClass.SIKJI:
                return Keys.ATLAS_FORCES_LARGE_7;
        }
        return Keys.ATLAS_FORCES_SEMI_LARGE;
    }

    public static getLargeForceIcon(wc: WarriorClass, isMine: boolean | undefined) : string {
        if(wc == WarriorClass.ARCHANGEL){
            return isMine ? 'g_archangel' : 's_archangel';
        } else if(wc == WarriorClass.ARCHITITAN){
            return isMine ? 'g_archititan' : 's_archititan';
        } else if (wc == WarriorClass.ASG_PHOENIX){
            return isMine ? 'g_asg_phoenix' : 's_asg_phoenix';
        }
        return CreatureUtils.getForceIcon(wc, isMine);
    }

    public static getForceIcon(wc: WarriorClass, isMine: boolean | undefined) : string {
        switch (wc) {
            case WarriorClass.BEAST:
                return isMine ? 'g_beast' : 's_beast';
            case WarriorClass.DEMON:
                return isMine ? 'g_demon' : 's_demon';
            case WarriorClass.GIN:
                return isMine ? 'g_gin' : 's_gin';
            case WarriorClass.DRAGON:
                return isMine ? 'g_dragon' : 's_dragon';
            case WarriorClass.GOLEM:
                return isMine ? 'g_golem' : 's_golem';
            case WarriorClass.HUMAN:
                return isMine ? 'g_human' : 's_human';
            case WarriorClass.MAG:
                return isMine ? 'g_mag' : 's_mag';
            case WarriorClass.NECRO:
                return isMine ? 'g_necro' : 's_necro';
            case WarriorClass.PHOENIX:
            case WarriorClass.ASG_PHOENIX:
                return isMine ? 'g_phoenix' : 's_phoenix';
            case WarriorClass.SERPENT:
                return isMine ? 'g_serp' : 's_serp';
            case WarriorClass.WITCH:
                return isMine ? 'g_witch' : 's_witch';
            case WarriorClass.DRUID:
                return isMine ? 'g_druid' : 's_druid';
            case WarriorClass.WARLOCK:
                return isMine ? 'g_warlock' : 's_warclok';
            case WarriorClass.TITAN:
            case WarriorClass.ARCHITITAN:
                return isMine ? 'g_titan' : 's_titan';
            case WarriorClass.DRAGONSLAYER:
                return isMine ? 'g_dragonslayer' : 's_dragonslayer';
            case WarriorClass.SHAMAN:
                return isMine ? 'g_monk' : 's_monk';
            case WarriorClass.IFRIT:
                return isMine ? 'g_ifrit' : 's_ifrit';
            case WarriorClass.LIFE:
                return isMine ? 'g_life' : 's_life';
            case WarriorClass.DEATH:
                return isMine ? 'g_death' : 's_death';
            case WarriorClass.WIND:
                return isMine ? 'g_wind' : 's_wind';
            case WarriorClass.CHAOS:
                return isMine ? 'g_chaos' : 's_chaos';
            case WarriorClass.WALL:
                return isMine ? 'g_wall' : 's_wall';
            case WarriorClass.ELF:
                return isMine ? 'g_elf' : 's_elf';
            case WarriorClass.VAMPIRE:
                return isMine ? 'g_vampire' : 's_vampire';
            case WarriorClass.LORD:
                return isMine ? 'g_lord' : 's_lord';
            case WarriorClass.BERSERK:
                return isMine ? 'g_berserk' : 's_berserk';
            case WarriorClass.LUCK:
                return isMine ? 'g_luck' : 's_luck';
            case WarriorClass.SORCERER:
            case WarriorClass.PRIESTESS:
                return isMine ? 'g_sorcerer' : 's_sorcerer';
            case WarriorClass.ANGEL:
            case WarriorClass.ARCHANGEL:
                return isMine ? 'g_angel' : 's_angel';
            case WarriorClass.YOTUN:
                return isMine ? 'g_yotun' : 's_yotun';
            case WarriorClass.AS:
                return isMine ? 'g_as' : 's_as';
            case WarriorClass.CYCLOP:
                return isMine ? 'g_cyclop' : 's_cyclop';
            case WarriorClass.KEEPER:
                return isMine ? 'g_keeper' : 's_keeper';
            case WarriorClass.KOSCHEY:
                return isMine ? 'g_koschey' : 's_koschey';
            case WarriorClass.LIZARD:
                return isMine ? 'g_lizard' : 's_lizard';
            case WarriorClass.POISON:
                return isMine ? 'g_naga' : 's_naga';
            case WarriorClass.SHADOW:
                return isMine ? 'g_shadow' : 's_shadow';
            case WarriorClass.SPHINX:
                return isMine ? 'g_sphinx' : 's_sphinx';

            case WarriorClass.DRYAD:
                return isMine ? 'g_dryad' : 's_dryad';
            case WarriorClass.FACELESS:
                return isMine ? 'g_faceless' : 's_faceless';
            case WarriorClass.GORGON:
                return isMine ? 'g_gorgon' : 's_gorgon';
            case WarriorClass.LIGHTSHADOW:
                return isMine ? 'g_lightshadow' : 's_lightshadow';
            case WarriorClass.INKUB:
                return isMine ? 'g_inkub' : 's_inkub';
            case WarriorClass.SUKKUB:
                return isMine ? 'g_sukkub' : 's_sukkub';
            case WarriorClass.MANTICORE:
                return isMine ? 'g_manti' : 's_manti';
            case WarriorClass.RIDER:
                return isMine ? 'g_rider' : 's_rider';
            case WarriorClass.NUN:
                return isMine ? 'g_nun' : 's_nun';
            case WarriorClass.FIRE:
                return isMine ? 'g_fire' : 's_fire';
            case WarriorClass.MARTYR:
                return isMine ? 'g_martyr' : 's_martyr';
            case WarriorClass.ABYSSSOUL:
                return isMine ? 'g_abyss' : 's_abyss';
            case WarriorClass.BABAYAGA:
                return isMine ? 'g_yaga' : 's_yaga';
            case WarriorClass.BALTAZAR:
                return isMine ? 'g_balt' : 's_balt';
            case WarriorClass.SIKJI:
                return isMine ? 'g_sikji' : 's_sikji';
        }
        return '';
    }

    public static getClassNameFull(type: WarriorClass): string {
        if(type == WarriorClass.ASG_PHOENIX){
            return Tr('asg.phonix.full');
        }
        return this.getClassName(type);
    }

    public static getClassName(type: WarriorClass): string {
        switch (type) {
            case WarriorClass.BEAST:
                return Tr('zver');
            case WarriorClass.DEMON:
                return Tr('demon');
            case WarriorClass.DRAGON:
                return Tr('drakon');
            case WarriorClass.GIN:
                return Tr('dzhinn');
            case WarriorClass.GOLEM:
                return Tr('golem');
            case WarriorClass.HUMAN:
                return Tr('chelovek');
            case WarriorClass.LIZARD:
                return Tr('jascher');
            case WarriorClass.MAG:
                return Tr('mag');
            case WarriorClass.NECRO:
                return Tr('nekromant');
            case WarriorClass.SERPENT:
                return Tr('zmey');
            case WarriorClass.TROLL:
                return Tr('troll');
            case WarriorClass.SKULD:
                return Tr('skuld');
            case WarriorClass.BALTAZAR:
                return Tr('baltazar');
            case WarriorClass.SIKJI:
                return Tr('sikji');
            case WarriorClass.PHOENIX:
                return Tr('feniks');
            case WarriorClass.WARLOCK:
                return Tr('warlock');
            case WarriorClass.TITAN:
                return Tr('titan');
            case WarriorClass.DRUID:
                return Tr('druid');
            case WarriorClass.WITCH:
                return Tr('witch');
            case WarriorClass.SHAMAN:
                return Tr('shaman');
            case WarriorClass.DRAGONSLAYER:
                return Tr('dragonslayer');
            // case WarriorClass.DRAGON_2:
            //     return Tr('dragon_2');

            case WarriorClass.IFRIT:
                return Tr('ifrit');
            case WarriorClass.LIFE:
                return Tr('life');
            case WarriorClass.DEATH:
                return Tr('death');
            case WarriorClass.CHAOS:
                return Tr('chaos');
            case WarriorClass.WALL:
                return Tr('wall');
            case WarriorClass.LORD:
                return Tr('lord');
            case WarriorClass.VAMPIRE:
                return Tr('vamp');
            case WarriorClass.LUCK:
                return Tr('luck');
            case WarriorClass.BERSERK:
                return Tr('bers');
            case WarriorClass.WIND:
                return Tr('wind');
            case WarriorClass.SORCERER:
                return Tr('sorc');
            case WarriorClass.ANGEL:
                return Tr('angel');
            case WarriorClass.ARCHANGEL:
                return Tr('archangel');
            case WarriorClass.ARCHITITAN:
                return Tr('archititan');
            case WarriorClass.YOTUN:
                return Tr('yotun');
            case WarriorClass.ASG_PHOENIX:
                return Tr('asg.phonix');
            case WarriorClass.EMPTY:

            case WarriorClass.KOSCHEY:
                return Tr('koschey');
            case WarriorClass.CYCLOP:
                return Tr('cyclop');
            case WarriorClass.POISON:
                return Tr('poison');
            case WarriorClass.KEEPER:
                return Tr('keeper');
            case WarriorClass.SPHINX:
                return Tr('sphinx');
            case WarriorClass.NORN:
                return Tr('norn');
            case WarriorClass.PROVOKATOR:
                return Tr('provocator');
            case WarriorClass.SHADOW:
                return Tr('shadow');
            case WarriorClass.PRIESTESS:
                return Tr('priestess');
            case WarriorClass.AS:
                return Tr('as');
            case WarriorClass.MANTICORE:
                return Tr('manticore');
            case WarriorClass.SUKKUB:
                return Tr('sukkub');
            case WarriorClass.INKUB:
                return Tr('inkub');
            case WarriorClass.RIDER:
                return Tr('rider');
            case WarriorClass.NUN:
                return Tr('nun');
            case WarriorClass.FIRE:
                return Tr('fire');
            case WarriorClass.GORGON:
                return Tr('gorgon');
            case WarriorClass.LIGHTSHADOW:
                return Tr('lightshadow');
            case WarriorClass.MARTYR:
                return Tr('martyr');
            case WarriorClass.DRYAD:
                return Tr('dryad');
            case WarriorClass.ABYSSSOUL:
                return Tr('abyssoul');
            case WarriorClass.FACELESS:
                return Tr('faceless');
            case WarriorClass.BABAYAGA:
                return Tr('babayaga');
            default:
                return '-';
        }

        console.log('Unknown warrior class: ' + String(type));
        return '';
    }

    public static getClassNameGenetive(type: WarriorClass): string {
        switch (type) {
            case WarriorClass.BEAST:
                return Tr('beast.gen');
            case WarriorClass.DEMON:
                return Tr('demon.gen');
            case WarriorClass.DRAGON:
                return Tr('dragon.gen');
            case WarriorClass.GIN:
                return Tr('gin.gen');
            case WarriorClass.GOLEM:
                return Tr('golem.gen');
            case WarriorClass.HUMAN:
                return Tr('human.gen');
            case WarriorClass.LIZARD:
                return Tr('lizard.gen');
            case WarriorClass.ELF:
                return Tr('elf.gen');
            case WarriorClass.MAG:
                return Tr('mag.gen');
            case WarriorClass.NECRO:
                return Tr('necro.gen');
            case WarriorClass.SERPENT:
                return Tr('serpent.gen');
            case WarriorClass.TROLL:
                return Tr('troll.gen');
            case WarriorClass.PHOENIX:
                return Tr('phoenix.gen');
            case WarriorClass.WARLOCK:
                return Tr('warlock.gen');
            case WarriorClass.TITAN:
                return Tr('titan.gen');
            case WarriorClass.DRUID:
                return Tr('druid.gen');
            case WarriorClass.WITCH:
                return Tr('witch.gen');
            case WarriorClass.SHAMAN:
                return Tr('shaman.gen');
            case WarriorClass.DRAGONSLAYER:
                return Tr('dragonslayer.gen');
            case WarriorClass.IFRIT:
                return Tr('ifrit.gen');
            case WarriorClass.LIFE:
                return Tr('life.gen');
            case WarriorClass.DEATH:
                return Tr('death.gen');
            case WarriorClass.CHAOS:
                return Tr('chaos.gen');
            case WarriorClass.WALL:
                return Tr('wall.gen');
            case WarriorClass.LORD:
                return Tr('lord.gen');
            case WarriorClass.VAMPIRE:
                return Tr('vamp.gen');
            case WarriorClass.LUCK:
                return Tr('luck.gen');
            case WarriorClass.BERSERK:
                return Tr('bers.gen');
            case WarriorClass.WIND:
                return Tr('wind.gen');
            case WarriorClass.SORCERER:
                return Tr('sorc.gen');
            case WarriorClass.ANGEL:
                return Tr('angel.gn');
            case WarriorClass.ARCHANGEL:
                return Tr('archangel.gn');
            case WarriorClass.ARCHITITAN:
                return Tr('archititan.gn');
            case WarriorClass.YOTUN:
                return Tr('yotun.gn');
            case WarriorClass.ASG_PHOENIX:
                return Tr('asg.phonix.gn');
            case WarriorClass.EMPTY:
                return '';

            case WarriorClass.KOSCHEY:
                return Tr('koschey.gn');
            case WarriorClass.CYCLOP:
                return Tr('cyclop.gn');
            case WarriorClass.POISON:
                return Tr('poison.gn');
            case WarriorClass.KEEPER:
                return Tr('keeper.gn');
            case WarriorClass.SPHINX:
                return Tr('sphinx.gn');
            case WarriorClass.NORN:
                return Tr('norn.gn');
            case WarriorClass.PROVOKATOR:
                return Tr('provocator.gn');
            case WarriorClass.SHADOW:
                return Tr('shadow.gn');
            case WarriorClass.PRIESTESS:
                return Tr('priestess.gn');
            case WarriorClass.AS:
                return Tr('as.gn');
            case WarriorClass.MANTICORE:
                return Tr('manticore.gn');
            case WarriorClass.SUKKUB:
                return Tr('sukkub.gn');
            case WarriorClass.INKUB:
                return Tr('inkub.gn');
            case WarriorClass.RIDER:
                return Tr('rider.gn');
            case WarriorClass.NUN:
                return Tr('nun.gn');
            case WarriorClass.FIRE:
                return Tr('fire.gn');
            case WarriorClass.GORGON:
                return Tr('gorgon.gn');
            case WarriorClass.LIGHTSHADOW:
                return Tr('lightshadow.gn');
            case WarriorClass.MARTYR:
                return Tr('martyr.gn');
            case WarriorClass.DRYAD:
                return Tr('dryad.gn');
            case WarriorClass.ABYSSSOUL:
                return Tr('abyssoul.gn');
            case WarriorClass.FACELESS:
                return Tr('faceless.gn');
            case WarriorClass.BABAYAGA:
                return Tr('babayaga.gn');
        }

        console.log('Unknown warrior class: ' + String(type));
        return '';
    }

    public static hasForce(c: Creature, wc: WarriorClass): boolean {
        for (let force of c.forcesV2) {
            if (force.warriorClass == wc) {
                return true;
            }
        }
        return false;
    }

    public static fireEffect(c: Creature) {
        if (c.identifier && c.identifier.startsWith('vip_')) {
            return 'fire_vip';
        }
        return 'fire_reg';
    }

    public static gorgonEffect(c: Creature) {
        if (c.identifier && c.identifier.startsWith('vip_')) {
            return 'gorgon_vip';
        }
        return 'gorgon_reg';
    }

    public static getForceIconForTwelveX(forceNum: number): number {
        switch (forceNum) {
            case 0: return -37;
            case 1: return -43;
            case 2: return -43;
            case 3: return -41;
            case 4: return -33;
            case 5: return -19;

            case 6: return 37;
            case 7: return 43;
            case 8: return 43;
            case 9: return 41;
            case 10: return 33;
            case 11: return 19;
        }
        return 0;
    }

    public static getForceIconForTwelveY(forceNum: number): number {
        switch (forceNum) {
            case 0: return 31;
            case 1: return 13;
            case 2: return -7;
            case 3: return -26;
            case 4: return -43;
            case 5: return -54;

            case 6: return 31;
            case 7: return 13;
            case 8: return -7;
            case 9: return -26;
            case 10: return -43;
            case 11: return -54;
        }
        return 0;
    }

    static premiumForce(wc: string): string {
        return 'g_' + wc;
    }

    public static isPremium(creature: Creature): boolean {
        return Keys.PREMIUM_CREATURES.includes(creature.imageId);
    }
}
